import React from "react"
import Layout from "../components/layout"
import Steps from "../page_components/howitworks/steps"
import DetailsB from "../page_components/howitworks/details-B"
import Download from "../page_components/howitworks/download"
import GetInvolved from "../page_components/home/get-involved"
import "./how-it-works.css"

const HowItWorks = () => (
  <Layout>
    <div className="container howitworks-summaryContainer">
      <h1> How It Works </h1>
      <h2>
        Select icons to describe your emergency and we do the rest: <br/>
        Connect you with 911 and emergency services
      </h2>
    </div>

    <Steps />
    <DetailsB />
    <div
      style={{
        width: "100%",
        height: "1070px",
      }}
    >
      <iframe
        src="https://rahul-dev--text911-redesign.netlify.app/embed"
        scrolling="no" 
        title="Map of Counties with Text-to-911"
        style={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          border: "none",
        }}
      ></iframe>
    </div>

    
    <Download/>
    <GetInvolved />
  </Layout>
)

export default HowItWorks
