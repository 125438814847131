import React from "react"

import step1 from "../../images/pwa-steps/accesSOS-step1.png"
import step2 from "../../images/pwa-steps/accesSOS-step2.png"
import step3 from "../../images/pwa-steps/accesSOS-step3.png"
import step4 from "../../images/pwa-steps/accesSOS-step4.png"
import step5 from "../../images/pwa-steps/accesSOS-step5-1.png"
import step5v2 from "../../images/pwa-steps/accesSOS-step5-2.png"
import "./steps.css"

const Steps = () => (
  <div>
    <div className="container steps-container steps-orderA">
      <div className="steps-imgContainer">
        <img src={step1} alt="" />
      </div>
      <div className="steps-instrContainer">
        <h1> Step 1 </h1>
        <h2> THE ESSENTIALS </h2>
        <h3> Your location</h3>
        <p>
          We automatically collect your address using the phone's GPS data. Verify to make sure
          it's accurate.
        </p>
        <h3> Select your emergency </h3>
        <p> Specify which emergency service(s) you need for your situation. </p>
      </div>
    </div>
    <div className="container steps-container steps-orderB">
      <div className="steps-imgContainer">
        <img src={step2} alt="" />
      </div>
      <div className="steps-instrContainer">
        <h1> Step 2 </h1>
        <h2> YOUR SAFETY </h2>
        <h3> About safety </h3>
        <p>
          {" "}
          We want to make sure you are safe. This information helps emergency
          dispatchers determine what type of help and how much help to send.{" "}
        </p>
      </div>
    </div>
    <div className="container steps-container third-step steps-orderA">
      <div className="steps-imgContainer">
        <img src={step3} alt="" />
      </div>
      <div className="steps-instrContainer">
        <h1> Step 3</h1>
        <h2> ADDITIONAL DETAILS </h2>
        <h3> Describe your emergency </h3>
        <p>
          Select the icon that applies to you. We communicate your type of emergency clearly to emergency services.
        </p>
        <h3> Add more about your location </h3>
        <p>
          Additional details like apartment or floor number make it easier for first responders to locate you, if needed.
        </p>
      </div>
    </div>

    <div className="container steps-container steps-orderB">
      <div className="steps-imgContainer">
        <img src={step4} alt="" />
      </div>
      <div className="steps-instrContainer">
        <h1>Last Step</h1>
        <h2> REVIEW AND CONFIRM </h2>
        <h3>What we do </h3>
        <p>
          We send this information to dispatch so they can make the best decision on how to send help to you.
        </p>
      </div>
    </div>

    <div className="container steps-container steps-orderA">
      <div className="steps-doneImgContainer">
        <div>
          <h5>In Text-to-911 area</h5>
          <img src={step5} alt=""/>
        </div>
        <div>
          <h5>Not in Text-to-911 area</h5>
          <img src={step5v2} alt=""/>
        </div>
      </div>
      <div className="steps-instrContainer doneContainer">
        <h1> All Done! </h1>
        <h2> HELP CONTACTED </h2>
      </div>
      
    </div>
  </div>
)

export default Steps
